import genericMarkers from "../images/markers/Generic-map-markers.png";
import FloMarkers from "../images/markers/FLO-map-markers.png";
import CeMarkers from "../images/markers/CE-map-markers.png";
import EcnMarkers from "../images/markers/ECN-map-markers.png";
import BchMarkers from "../images/markers/BCH-map-markers.png";

import greenSmallCluster from "../images/ce/map-cluster-green-S.png";
import greenMediumCluster from "../images/ce/map-cluster-green-M.png";
import greenLargeCluster from "../images/ce/map-cluster-green-L.png";

import yellowSmallCluster from "../images/ce/map-cluster-yellow-S.png";
import yellowMediumCluster from "../images/ce/map-cluster-yellow-M.png";
import yellowLargeCluster from "../images/ce/map-cluster-yellow-L.png";

import blueSmallCluster from "../images/ce/map-cluster-blue-S.png";
import blueMediumCluster from "../images/ce/map-cluster-blue-M.png";
import blueLargeCluster from "../images/ce/map-cluster-blue-L.png";

import greySmallCluster from "../images/ce/map-cluster-gray-S.png";
import greyMediumCluster from "../images/ce/map-cluster-gray-M.png";
import greyLargeCluster from "../images/ce/map-cluster-gray-L.png";


export default {
    GoogleMapsApiKey: "AIzaSyBAQxXgjOxvHkJCLYj4CxTBBANjpitQE30",
    Culture: "en-US",
    Network: "ce",
    RenderLegend: false,
    InfoWindowBorderRadius: 0,
    UrlStationMarkers: "/network/markers.json",
    UrlStationsInfo: "/network/stationsinfo.json",
    UrlNearestStation: "/network/neareststation.json",
    MapCluster: {
        Green: {
            Small: `${greenSmallCluster}`,
            Medium: `${greenMediumCluster}`,
            Large: `${greenLargeCluster}`
        },
        Yellow: {
            Small: `${yellowSmallCluster}`,
            Medium: `${yellowMediumCluster}`,
            Large: `${yellowLargeCluster}`
        },
        Blue: {
            Small: `${blueSmallCluster}`,
            Medium: `${blueMediumCluster}`,
            Large: `${blueLargeCluster}`
        },
        Grey: {
            Small: `${greySmallCluster}`,
            Medium: `${greyMediumCluster}`,
            Large: `${greyLargeCluster}`
        }
    },
    MapMarkers: {
        FLO: `${FloMarkers}`,
        CE: `${CeMarkers}`,
        ECN: `${EcnMarkers}`,
        BCH: `${BchMarkers}`,
        Generic: `${genericMarkers}`
    },
    Translations: {
        en: { "FLO network": "Electric Circuit" },
        fr: { "FLO network": "Circuit �lectrique" }
    },
    InitialMapBounds: {
        NE: {
            latitude: 58.631217,
            longitude: -69.664307,
        },
        SW: {
            latitude: 47.279229,
            longitude: -128.875122,
        },
        Zoom: 2
    }
};