import genericMarkers from "../images/markers/Generic-map-markers.png";
import FloMarkers from "../images/markers/FLO-map-markers.png";
import CeMarkers from "../images/markers/CE-map-markers.png";
import EcnMarkers from "../images/markers/ECN-map-markers.png";
import BchMarkers from "../images/markers/BCH-map-markers.png";

import greenSmallCluster from "../images/ecn/map-cluster-green-S.png";
import greenMediumCluster from "../images/ecn/map-cluster-green-M.png";
import greenLargeCluster from "../images/ecn/map-cluster-green-L.png";

import yellowSmallCluster from "../images/ecn/map-cluster-yellow-S.png";
import yellowMediumCluster from "../images/ecn/map-cluster-yellow-M.png";
import yellowLargeCluster from "../images/ecn/map-cluster-yellow-L.png";

import blueSmallCluster from "../images/ecn/map-cluster-blue-S.png";
import blueMediumCluster from "../images/ecn/map-cluster-blue-M.png";
import blueLargeCluster from "../images/ecn/map-cluster-blue-L.png";

import greySmallCluster from "../images/ecn/map-cluster-gray-S.png";
import greyMediumCluster from "../images/ecn/map-cluster-gray-M.png";
import greyLargeCluster from "../images/ecn/map-cluster-gray-L.png";


export default {
  GoogleMapsApiKey: "AIzaSyDjfcXICtr-dgVIqXJL6kbaUyIruXL1e1Y",
  Culture: "en-US",
  Network: "ecn",
  RenderLegend: true,
  InfoWindowBorderRadius: 0,
  UrlStationMarkers: "https://emobility.echargenetwork.co/v3.0/map/markers/search",
  UrlStationsInfo: "https://emobility.echargenetwork.co/v3.0/parks/station/",
  UrlNearestStation: "https://emobility.echargenetwork.co/v3.0/parks/nearest",		
  MapCluster: {
    Green: {
      Small: `${greenSmallCluster}`,
      Medium: `${greenMediumCluster}`,
      Large: `${greenLargeCluster}`
    },
    Yellow: {
      Small: `${yellowSmallCluster}`,
      Medium: `${yellowMediumCluster}`,
      Large: `${yellowLargeCluster}`
    },
    Blue: {
      Small: `${blueSmallCluster}`,
      Medium: `${blueMediumCluster}`,
      Large: `${blueLargeCluster}`
    },
    Grey: {
      Small: `${greySmallCluster}`,
      Medium: `${greyMediumCluster}`,
      Large: `${greyLargeCluster}`
    }
  },
  MapMarkers: {
    FLO: `${FloMarkers}`,
    CE: `${CeMarkers}`,
    ECN: `${EcnMarkers}`,
    BCH: `${BchMarkers}`,
    Generic: `${genericMarkers}`
  },
  Translations: {
    en: { "FLO network": "eCharge Network" },
    fr: { "FLO network": "Le Réseau branché" }
  },
  InitialMapBounds: {
    NE: {
        latitude: 58.631217,
        longitude: -69.664307,
    },
    SW: {
        latitude: 47.279229,
        longitude: -128.875122,
    },
    Zoom: 2
  }
};
