import genericMarkers from "../images/flo/FLO-Generic-Map-Markers.png";

import greenSmallCluster from "../images/flo/map-cluster-green-S.png";
import greenMediumCluster from "../images/flo/map-cluster-green-M.png";
import greenLargeCluster from "../images/flo/map-cluster-green-L.png";

import yellowSmallCluster from "../images/flo/map-cluster-yellow-S.png";
import yellowMediumCluster from "../images/flo/map-cluster-yellow-M.png";
import yellowLargeCluster from "../images/flo/map-cluster-yellow-L.png";

import blueSmallCluster from "../images/flo/map-cluster-blue-S.png";
import blueMediumCluster from "../images/flo/map-cluster-blue-M.png";
import blueLargeCluster from "../images/flo/map-cluster-blue-L.png";

import greySmallCluster from "../images/flo/map-cluster-gray-S.png";
import greyMediumCluster from "../images/flo/map-cluster-gray-M.png";
import greyLargeCluster from "../images/flo/map-cluster-gray-L.png";

export default {
  GoogleMapsApiKey: "AIzaSyAk5InG1tq61IF4rH5UW4cab6Zs74yrlQs",
  Culture: "en-US",
  Network: "flo",
  RenderLegend: true,
  InfoWindowBorderRadius: 15,
  UrlStationMarkers: "https://emobility.flo.ca/v3.0/map/markers/search",
  UrlStationsInfo: "https://emobility.flo.ca/v3.0/parks/station/",
  UrlNearestStation: "https://emobility.flo.ca/v3.0/parks/nearest",
  MapCluster: {
    Green: {
      Small: greenSmallCluster,
      Medium: greenMediumCluster,
      Large: greenLargeCluster
    },
    Yellow: {
      Small: yellowSmallCluster,
      Medium: yellowMediumCluster,
      Large: yellowLargeCluster
    },
    Blue: {
      Small: blueSmallCluster,
      Medium: blueMediumCluster,
      Large: blueLargeCluster
    },
    Grey: {
      Small: greySmallCluster,
      Medium: greyMediumCluster,
      Large: greyLargeCluster
    }
  },
  MapMarkers: {
    FLO: genericMarkers,
    CE: genericMarkers,
    ECN: genericMarkers,
    BCH: genericMarkers,
    Generic: genericMarkers
  },
  Translations: {},
  InitialMapBounds: {
    NE: {
        latitude: 58.631217,
        longitude: -69.664307,
    },
    SW: {
        latitude: 47.279229,
        longitude: -128.875122,
    },
    Zoom: 2
  }
};
