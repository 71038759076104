var dictionaries = {
  en: {
    "Find a station": "Find a station",
    Options: "Options",
    "All types": "All types",
    "All networks": "All networks",
    "FLO network": "FLO network",
    "All stations": "All stations",
    "Unknown pricing": "Unknown pricing",
    Available: "Available",
    "In Use": "In Use",
    Unknown: "Unknown",
    "Out of Service": "Out of Service",
    "Fast DC": "Fast DC",
    CHAdeMO: "CHAdeMO",
    J1772: "J1772",
    "SAE Combo": "SAE Combo",
    Mennekes: "Mennekes",
    "Standard 120V socket": "Standard 120V socket",
    "Unknown connector": "Unknown connector",
    FLO: "FLO",
    "Electric Circuit": "Electric Circuit",
    "eCharge Network": "eCharge Network",
    ChargePoint: "ChargePoint",
    "BC Hydro": "BC Hydro EV",
    Greenlots: "Shell Recharge",
    "Unknown Network": "Unknown Network",
    "Unknown pricing": "Unknown pricing"
  },
  fr: {
    "Find a station": "Trouver une station",
    Options: "Options",
    "All types": "Tous les types",
    "All networks": "Tous les réseaux",
    "FLO network": "Réseau FLO",
    "All stations": "Toutes les stations",
    "Unknown pricing": "Prix inconnu",
    Available: "Disponible",
    "In Use": "Utilisé",
    Unknown: "Inconnu",
    "Out of Service": "Hors service",
    "Fast DC": "Rapide DC",
    CHAdeMO: "CHAdeMO",
    J1772: "J1772",
    "SAE Combo": "SAE Combo",
    Mennekes: "Mennekes",
    "Standard 120V socket": "Prise 120V standard",
    "Unknown connector": "Connecteur inconnu",
    FLO: "FLO",
    "Electric Circuit": "Le circuit électrique",
    "eCharge Network": "Réseau branché",
    ChargePoint: "ChargePoint",
    "BC Hydro": "BC Hydro EV",
    Greenlots: "Shell Recharge",
    "Unknown Network": "Réseau inconnu",
    "Unknown pricing": "Tariff inconnu"
  }
};

let language = "en";

const translation = key => dictionaries[language][key];

translation.setLanguage = function(lang) {
  language = lang;
};

translation.setTranslations = function(overrides) {
  Object.keys(overrides).forEach(lang =>
    Object.keys(overrides[lang]).forEach(key => {
      dictionaries[lang][key] = overrides[lang][key];
    })
  );
};

export { translation };
